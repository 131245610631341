import React from "react";
import enzi_theme from "../../theme/theme";
import { CustomText } from "./text";
import { ColumnContainer } from "./containers";
import { CircularProgress } from "@mui/material";

export default function StatItem({
  value = '-',
  title,
  loading,
  width = "100%",
  marginBotton = "0px",
}) {
  return (
    <ColumnContainer
      marginBottom={marginBotton}
      width={width}
      height="100px"
      alignItems="flex-start"
      extraSX={{
        backgroundColor: enzi_theme.palette.secondary.main,
        borderRadius: "10px",
        padding: "10px 15px",
      }}
    >
      <CustomText
        variant="h4"
        text={loading ? <CircularProgress color="primary" /> : value}
        fontWeight="900"
        color={enzi_theme.palette.common.white}
      />
      <CustomText
        variant="body1"
        text={title}
        fontWeight="700"
        color="primary"
      />
    </ColumnContainer>
  );
}
