import React from "react";
import { CautionTextContainer, ModalContainer, WarningTextContainer } from "../global/containers";
import { Typography } from "@mui/material";
import { CustomText } from "../global/text";

export default function ConfirmationModal({
	modalOpen,
	toggleModal,
	onConfirmation,
	itemType = "item",
	itemName = "Unknown Item",
	confirmType,
}) {

	return (
		<ModalContainer
			title=""
			modalOpen={modalOpen}
			height="auto"
			type="warning"
			actionButtonText="Yes"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={onConfirmation}
		>
			{confirmType === "delete"  && (
				<WarningTextContainer>
				<Typography color={"error"} padding={"10px"}>
					You are about to delete the {itemType}
					<CustomText
						marginBottom="0px"
						fontWeight="700"
						color="error"
						text={itemName}
					/>
					This action is not reversible, are you sure you want to continue?
				</Typography>
			</WarningTextContainer>
			)}

			{(confirmType === "disable" || confirmType === "enable") && (
				<CautionTextContainer>
					<Typography color={"success"} padding={"10px"}>
						You are about to {confirmType} the {itemType}
						<CustomText
							marginBottom="0px"
							fontWeight="700"
							color="success"
							text={itemName}
						/>
						To reverse this, {confirmType === "disable" ? "enable" : "disable"} it again.
					</Typography>
				</CautionTextContainer>
			)}
		</ModalContainer>
	);
}
