import React, { useCallback, useRef } from "react";
import { ModalContainer } from "../global/containers";
import AddNewSwapStationForm from "../forms/addNewSwapStationForm";
import AddNewChargeStationForm from "../forms/addNewChargeStationForm"; // Import the Charge Station form
import { editSwapStation } from "../../services/swapStation"; // Import the edit services
import { editChargeStation } from "../../services/chargeStationService"; // Import the edit services
import { useDispatch } from "react-redux";
import { handleErrorResponse } from "../../util/util";
import { Box } from "@mui/material";

export default function EditStationModal({
	modalOpen,
	toggleModal,
	station,
	onSuccess,
	type, 
}) {
	const dispatch = useDispatch();
	const handleSubmitRef = useRef();

	const onSubmit = useCallback(
		(data) => {
			const editService =
				type === "swap" ? editSwapStation : editChargeStation;
			editService(data, data.id)
				.then((res) => {
					if (onSuccess) {
						onSuccess(res);
					}
					toggleModal();
				})
				.catch((err) => {
					dispatch(handleErrorResponse(err));
					console.error(`Error editing ${type} station:`, err);
				});
		},
		[dispatch, toggleModal, onSuccess, type]
	);

	const handleSubmit = useCallback(() => {
		if (handleSubmitRef.current) {
			const stationData = handleSubmitRef.current.getData();
			if (stationData) {
				onSubmit(stationData);
			} else {
				console.error("Failed to get data from the form.");
			}
		} else {
			console.error("handleSubmitRef is not initialized.");
		}
	}, [onSubmit]);

	const FormComponent =
		type === "swap" ? AddNewSwapStationForm : AddNewChargeStationForm;

	return (
		<ModalContainer
			title={`Edit ${type === "swap" ? "Swap" : "Charge"} Station: ${
				station?.name || "Unknown"
			}`}
			modalOpen={modalOpen}
			height="80%"
			width="80%"
			maxWidth="1000px"
			actionButtonText="Save"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={handleSubmit}
		>
			<Box
				sx={{
					height: "calc(100% - 64px)",
					overflowY: "auto",
					padding: "16px",
				}}
			>
				<FormComponent station={station} ref={handleSubmitRef} />
			</Box>
		</ModalContainer>
	);
}
