import axios from "axios";
import { store } from "../store";

const getSecureRequest = (request) => {
	const user = store.getState().auth.user;
	if (user?.jwt !== undefined) {
		request.headers = {
			Authorization: `Bearer ${user.jwt}`,
		};
	} else {
		delete serviceAPI.defaults.headers.common.Authorization;
	}
	return request;
};

export const serviceAPI = axios.create();
serviceAPI.interceptors.request.use(
	(request) => {
		return getSecureRequest(request);
	},
	(error) => Promise.reject(error)
);
serviceAPI.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		// console.error(JSON.stringify(error));
		return Promise.reject(error);
	}
);

const serviceAPIJSON = axios.create();
serviceAPIJSON.interceptors.request.use(
	(request) => {
		let secureRequest = getSecureRequest(request);
		secureRequest.headers = {
			...secureRequest.headers,
			Accept: "application/json; charset=utf-8",
			"Content-Type": "application/json; charset=utf-8",
		};
		return secureRequest;
	},
	(error) => {
		// console.error(JSON.stringify(error));
		return Promise.reject(error);
	}
);

serviceAPIJSON.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		// console.error(JSON.stringify(error));
		return Promise.reject(error);
	}
);

export const serviceAPIDownloadFile = axios.create();
serviceAPIDownloadFile.interceptors.request.use(
	(request) => {
		let secureRequest = getSecureRequest(request);
		secureRequest.responseType = "blob";
		return secureRequest;
	},
	(error) => Promise.reject(error)
);
serviceAPIDownloadFile.interceptors.response.use(
	(response) => {
		let filename = "download.csv";
		let contentDisposition =
			response.headers["content-disposition"] ||
			response.headers["Content-Disposition"];

		if (contentDisposition) {
			try {
				filename = contentDisposition
					.split("filename=")[1]
					.replaceAll('"', "");
			} catch (error) {
				console.warn(
					"Error parsing Content-Disposition header:",
					error
				);
			}
		}

		let extension = filename.split(".").pop().toLowerCase();
		const url = window.URL.createObjectURL(response.data);
		const link = document.createElement("a");
		link.href = url;

		if (
			extension === "pdf" ||
			extension === "jpeg" ||
			extension === "png" ||
			extension === "jpg"
		) {
			link.target = "_blank";
		} else {
			link.setAttribute("download", filename);
		}

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);

		return response;
	},
	(error) => Promise.reject(error)
);
