import React, { useEffect, useRef, useState } from "react";
import { CardContainer } from "../global/containers";
import { BarChartItem } from "../global/charts";
import enzi_theme from "../../theme/theme";
import { requestedDispatchOperations } from "../../services/swapStation";

export default function SessionSwapsCard({ station }) {
	const cardContainerRef = useRef();
	const [cardContainerWidth, setCardContainerWidth] = useState(undefined);
	const [swapOperations, setSwapOperations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (cardContainerRef !== null && cardContainerRef.current !== null) {
			const containerWidth = cardContainerRef.current.getContainerWidth();
			if (!isNaN(containerWidth)) {
				setCardContainerWidth(containerWidth * 2);
			}
		}
	}, [cardContainerRef]);

	useEffect(() => {
		if (station && station.id) {
			setIsLoading(true);
			requestedDispatchOperations(station.id)
				.then((data) => {
					if (data && data.battery_swaps) {
						setSwapOperations(data.battery_swaps);
					} else {
						console.error("Unexpected data structure:", data);
						setSwapOperations([]);
					}
					setIsLoading(false);
				})
				.catch((error) => {
					console.error("Error fetching swap operations:", error);
					setIsLoading(false);
				});
		}
	}, [station]);

	// Process the swaps data
	const processedData = swapOperations.reduce((acc, swap) => {
		const [month, day, year] = swap.operation_date.split("/");
		const date = new Date(`${year}-${month}-${day}`);
		if (isNaN(date.getTime())) {
			console.error("Invalid date:", swap.operation_date);
			return acc; // Skip this swap
		}
		const monthName = date.toLocaleString("default", { month: "long" });
		const key = `${monthName} ${year}`;

		if (!acc[key]) {
			acc[key] = { issuedSwaps: 0, returnedSwaps: 0 };
		}

		if (swap.operation_type === "Issued") {
			acc[key].issuedSwaps += 1;
		} else if (swap.operation_type === "Received") {
			acc[key].returnedSwaps += 1;
		}

		return acc;
	}, {});

	const dataLabels = Object.keys(processedData).sort((a, b) => {
		const dateA = new Date(a);
		const dateB = new Date(b);
		return dateA - dateB;
	});

	const issuedSwaps = dataLabels.map((key) => processedData[key].issuedSwaps);
	// const returnedSwaps = dataLabels.map(
	// 	(key) => processedData[key].returnedSwaps
	// );

	return (
		<CardContainer
			ref={cardContainerRef}
			title="Station Swaps"
			width="100%"
			extraSX={{
				overflow: "visible",
				marginTop: "10px",
				alignItems: "flex-start",
			}}
			childrenContainerWidth="98%"
		>
			{isLoading ? (
				<p>Loading swap operations...</p>
			) : (
				<BarChartItem
					height={
						cardContainerWidth !== undefined
							? cardContainerWidth * (15 / 50)
							: 400
					}
					width={cardContainerWidth * 0.98}
					dataLabels={dataLabels}
					dataSeries={[
						{
							data: issuedSwaps,
							color: enzi_theme.palette.secondary.main,
							valueFormatter: (value) => `${value}`,
							label: "Issued Swaps",
						},
						// {
						// 	data: returnedSwaps,
						// 	color: enzi_theme.palette.primary.main,
						// 	valueFormatter: (value) =>
						// 		`${value}`,
						// 	label: "Returned Swaps",
						// },
					]}
					chartLabel="Station Swaps"
				/>
			)}
		</CardContainer>
	);
}
