import { useCallback, useEffect, useRef, useState } from "react";
import { CardContainer } from "../global/containers";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import ViewChargingAndPowerUsageModal from "../modals/viewChargingAndPowerUsageModal";
import {
	getChargingAndPowerUsage,
	downloadChargingAndPowerUsageReport,
} from "../../services/dashboardService";

import ChargingAndPowerReportModal from "../modals/chargingAndPowerReportModal";
import { issueWarnMessage } from "../../util/util";
import ActionableComponent from "../global/actionableComponent";

export function ChargingAndPowerUsageCard() {
	const cardContainerRef = useRef();
	const [viewModal, setViewModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [dataRows, setDataRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [downloadModalOpen, setDownloadModalOpen] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [timeRange, setTimeRange] = useState("day"); // Default to month

	const chargingAndPowerUsageColumns = [
		{
			field: "chargingStation",
			headerName: "Charging Station",
			width: 75,
			flex: 1,
		},
		{
			field: "batteriesCount",
			headerName: "Batteries",
			minWidth: 50,
			flex: 0.75,
		},
		{
			field: "powerUsage",
			headerName: "Power Usage (kWh)",
			minWidth: 75,
			flex: 0.75,
		},
		{
			field: "cost",
			headerName: "Cost (Kshs)",
			minWidth: 75,
			flex: 0.75,
		},
		{
			field: "amountOwed",
			headerName: "Amount Owed (Kshs)",
			minWidth: 75,
			flex: 0.75,
		},
		{
			field: "averageReceivedCharge",
			headerName: "AVG Received Charge (%)",
			minWidth: 75,
			flex: 0.75,
			align: "center",
		},
		{
			field: "actions",
			headerName: "Actions",
			align: "center",
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					menuOptions={[
						{
							label: "View",
							onClickHandler: () => handleViewClicked(params.row),
						},
					]}
				/>
			),
		},
	];

	const handleViewClicked = (row) => {
		setSelectedRow(row);
		setViewModal(true);
	};

	const getData = useCallback(
		(data = {}) => {
			let startDate = data.start_date ?? new Date().toISOString();
			let endDate = data.end_date ?? new Date().toISOString();

			if (startDate > endDate) {
				issueWarnMessage("Start date cannot be greater than end date");
				return;
			}

			setIsLoading(true);
			getChargingAndPowerUsage({
				start_date: startDate,
				end_date: endDate,
			})
				.then((response) => {
					setDataRows(response);
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[setDataRows]
	);

	useEffect(() => {
		getData();
	}, [getData]);

	const handleDownloadReportClicked = () => {
		setDownloadModalOpen(true);
	};

	const handleDownload = () => {
		const data = {
			start_date: new Date(startDate).toISOString(),
			end_date: new Date(endDate).toISOString(),
			report: "csv",
		};

		if (!validateData(data)) return;

		downloadChargingAndPowerUsageReport(data).finally(() => {
			setDownloadModalOpen(false);
			setStartDate(new Date());
			setEndDate(new Date());
		});
	};

	const validateData = (data) => {
		if (!startDate || !endDate) {
			issueWarnMessage("Please select a start and end date");
			return false;
		}
		if (startDate > endDate) {
			issueWarnMessage("Start date cannot be greater than end date");
			return false;
		}
		if (data.report && data.report !== "csv") {
			issueWarnMessage("Invalid report type");
			return false;
		}
		return true;
	};

	const handleStartDateChange = (date) => {
		setStartDate(date);
	};

	const handleEndDateChange = (date) => {
		setEndDate(date);
	};

	const handleTimeRangeChange = useCallback(
		(event) => {
			const newTimeRange = event.target.value;
			let startDate = new Date();
			let endDate = new Date();

			if (newTimeRange === "day") {
				startDate.setDate(startDate.getDate() - 1);
			} else if (newTimeRange === "week") {
				startDate.setDate(startDate.getDate() - 7);
			} else if (newTimeRange === "month") {
				startDate.setMonth(startDate.getMonth() - 1);
			}

			setStartDate(startDate);
			setEndDate(endDate);
			getData({
				start_date: startDate,
				end_date: endDate,
			});
			setTimeRange(newTimeRange);
		},
		[getData]
	);

	return (
		<CardContainer
			title="Charging and Power Usage"
			width={{ xs: "100%", lg: "100%" }}
			// height={"50vh"}
			childrenContainerWidth={"100%"}
			childrenContainerPadding={0}
			extraSX={{
				justifyContent: "center",
			}}
			hasActionableComponent={true}
			actionableComponent={
				<ActionableComponent
					showTimeRange={true}
					showDownload={true}
					actionableText="Download report"
					handleTimeRangeChange={handleTimeRangeChange}
					handleActionableClick={handleDownloadReportClicked}
				/>
			}
		>
			<CustomDataGrid
				columns={chargingAndPowerUsageColumns}
				rows={dataRows}
				isLoading={isLoading}
				containerRef={cardContainerRef}
				width="100%"
				height="100%"
			/>
			<ViewChargingAndPowerUsageModal
				modalOpen={viewModal}
				toggleModal={() => setViewModal(!viewModal)}
				selectedRow={selectedRow}
			/>
			<ChargingAndPowerReportModal
				modalOpen={downloadModalOpen}
				toggleModal={() => setDownloadModalOpen(!downloadModalOpen)}
				handleDownload={handleDownload}
				handleStartDateChange={handleStartDateChange}
				handleEndDateChange={handleEndDateChange}
				startDate={startDate}
				endDate={endDate}
			/>
		</CardContainer>
	);
}
