import React from "react";
import { ColumnContainer } from "./containers";
import { Box, CircularProgress } from "@mui/material";
import enzi_theme from "../../theme/theme";
import { CustomText } from "./text";

// import emptyIllustration = ;

export function LoadingOverlays({ height = "400px" }) {
	return (
		<ColumnContainer
			width={"100%"}
			height={height}
			alignItems="center"
			justifyContent="center"
			extraSX={{
				backgroundColor: enzi_theme.palette.common.white,
				margin: 0,
			}}
		>
			<CircularProgress color="secondary" size={"20px"} />
		</ColumnContainer>
	);
}

export function EmptyOverlays({
	height = "400px",
	width,
	message = "No data found",
}) {
	return (
		<ColumnContainer
			width={width}
			height={"auto"}
			alignItems="center"
			justifyContent="center"
			extraSX={{
				backgroundColor: enzi_theme.palette.common.white,
				margin: 0,
			}}
		>
			<Box
				component="img"
				src={require("../../assets/emptyIllustrations.png")}
				alt="empty"
				sx={{
					objectFit: "contain",
					width: "20%",
					height: "auto",
				}}
			/>
			<CustomText
				color={"secondary"}
				textAlign="center"
				variant="body2"
				fontWeight="600"
				marginBottom="10px"
				text={message}
			/>
		</ColumnContainer>
	);
}
