import React, { useCallback, useEffect, useState } from "react";
import {
  CardContainer,
  ColumnContainer,
  RowContainer,
} from "../global/containers";
import StatItem from "../global/statItem";
import { getElectricVehiclesStats } from "../../services/electricVehicle";

export default function BikeOverViewStatsCard() {
  const [stats,setStats] = useState()

  const fetchStats = useCallback( async function(){
    getElectricVehiclesStats()
      .then((res => {
        setStats(res)
      }))
  },[]);

  useEffect(() => {
    fetchStats()
  },[fetchStats])

  return (
    <CardContainer
      title="Bike Overview Stats"
      width={{ xs: "100%", lg: "33%" }}
      height="auto"
      childrenContainerWidth="97%"
    >
      <ColumnContainer
        width="100%"
        height="auto"
        padding="5px 10px"
        marginBottom="5px"
        extraSX={{
          gap: "10px",
        }}
      >
        <RowContainer
          justifyContent="space-between"
          alignItems="start"
          padding="0px"
          marginBottom="0px"
          responsive={false}
          extraSX={{
            gap: "10px",
          }}
        >
          <StatItem title={"Total Active Bikes "} value={stats?.active_vehicles ?? "-"} />
          <StatItem title={"Average Daily Bike Mileage"} value={`${stats?.average_distance ?? "-"} KMs`} />
        </RowContainer>
        <RowContainer
          justifyContent="space-between"
          alignItems="start"
          padding="0px"
          marginBottom="0px"
          responsive={false}
          extraSX={{
            gap: "10px",
          }}
        >
          <StatItem title={"Residue Power"} value={`${stats?.resudual_power  ?? "-"} %`} />
          <StatItem title={"Total Month’s Bikes Revenue"} value={`${stats?.months_revenue ?? "-"} KES`} />
        </RowContainer>
      </ColumnContainer>
    </CardContainer>
  );
}
