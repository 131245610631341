import React from 'react'
import { ColumnContainer, ModalContainer, RowContainer } from '../global/containers'
import CustomDatePicker from './customDatePicker';
import dayjs from 'dayjs';

function ChargingAndPowerReportModal(props) {
  const today = dayjs().endOf('day');
  return (
  <ModalContainer
    title='Download Charging & Power Report'
    modalOpen={props.modalOpen}
    height="auto"
    actionButtonText="Download Report"
    handleCloseModal={props.toggleModal}
    handleCancelClick={props.toggleModal}
    handleActionClick={props.handleDownload}
  >
      <RowContainer
        justifyContent="space-between"
        padding="0px"
        marginBottom="0px"
        alignItems="start"
        width="100%"
        extraSX={{ flexWrap: { xs: "wrap", lg: "nowrap" } }}
      >
        <ColumnContainer extraSX={{width: {xs: "100%", lg: "50%"}}}>
          <CustomDatePicker label="Start Date" handleChange={props.handleStartDateChange} value={props.startDate} maxDate={today} />
        </ColumnContainer>
        <ColumnContainer extraSX={{width: {xs: "100%", lg: "50%"}}}>
          <CustomDatePicker label="End Date" handleChange={props.handleEndDateChange} value={props.endDate} maxDate={today} />
        </ColumnContainer>
      </RowContainer>
  </ModalContainer>
  )
}

export default ChargingAndPowerReportModal