import React, { useCallback, useEffect, useState, useMemo } from "react";
import { CardContainer } from "../global/containers";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import AddNewStationModal from "../modals/addNewStationModal";
import AddNewChargeStationForm from "../forms/addNewChargeStationForm";
import EditStationModal from "../modals/editStationModal";
import ConfirmationModal from "../modals/confirmationModal";
import { useNavigate } from "react-router-dom";
import {
	deleteChargeStation,
	addChargeStation,
} from "../../services/chargeStationService";
import { chargingStationMetrics } from "../../services/metricsService";
import ActionableComponent from "../global/actionableComponent";
import { downloadChargeStationMetrics } from "../../services/chargeStationService";
import DateRangeReportModal from "../modals/dateRangeReportModal";
import { handleErrorResponse, issueSuccessMessage } from "../../util/util";
import dayjs from "dayjs";

export default function ChargeListCard({
	title = "Charge Stations",
	height = "75vh",
	width = "100%",
	showTimeRange = false,
	showAddButton = true,
	showSearch = true,
	showDownload = false,
	customColumns,
	onRowClick,
}) {
	const navigator = useNavigate();
	const [AddmodalOpen, setAddModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [stationToEdit, setStationToEdit] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [stationToDelete, setStationToDelete] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const [chargeStationList, setChargeStationList] = useState([]);
	const [filteredChargeStations, setFilteredChargeStations] = useState([]);
	const [timeRange, setTimeRange] = useState("month");

	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const toggleModalAddModal = useCallback(() => {
		console.log("toggleModalAddModal");
		setAddModalOpen((prevState) => !prevState);
	}, []);

	const toggleModalEditModal = useCallback(() => {
		setEditModalOpen((prevState) => !prevState);
	}, []);

	const chargeStationListColumns = useMemo(
		() =>
			customColumns || [
				{
					field: "chargeStationName",
					headerName: "Charge Station Name",
					width: 300,
				},
				{
					field: "capacity",
					headerName: "Capacity(#)",
					minWidth: 100,
					flex: 1,
				},
				{
					field: "monthsRevenue",
					headerName: `${
						timeRange.charAt(0).toUpperCase() + timeRange.slice(1)
					}'s Revenue(KES)`,
					minWidth: 100,
					flex: 1,
					valueFormatter: (value) => `${value} KES`,
				},
				{
					field: "batteries_dispatched",
					headerName: "Batteries Dispatched(#)",
					minWidth: 100,
					flex: 1,
					valueFormatter: (value) => `${value} batteries`,
				},
				{
					field: "actions",
					headerName: "Actions",
					minWidth: 100,
					flex: 1,
					renderCell: (params) => (
						<CustomDataGridActionsDropdownMenu
							width="100px"
							menuOptions={[
								{
									label: "View",
									onClickHandler: () =>
										navigator(
											`/charge-station/${params.row.id}`,
											{
												state: {
													chargeStation: params.row,
												},
											}
										),
								},
								{
									label: "Edit",
									onClickHandler: () =>
										editBatteryInfo(params.row),
								},
								{
									label: "Delete",
									onClickHandler: () =>
										handleDelete(params.row),
								},
							]}
						/>
					),
				},
			]
	);

	const fetchChargeStationList = useCallback(async function (range) {
		setIsLoading(true);
		try {
			const res = await chargingStationMetrics(range);
			if (res && Array.isArray(res.stations)) {
				const formattedData = res.stations.map((station) => ({
					id: station.id,
					chargeStationName: station.name,
					dailySwapAverage: Number(
						(
							(station.g3_swaps + station.g5_swaps) /
							(range === "week" ? 7 : range === "day" ? 1 : 30)
						).toFixed(2)
					),
					monthsRevenue: parseFloat(station.revenue),
					battery_dispatched: station.battery_inventory_count,
					capacity: station.capacity,
					utilization: station.utilization_rate,
					powerConsumed: station.power_consumed,
					batteries_dispatched: station.batteries_dispatched,
					...station,
				}));
				setChargeStationList(formattedData);
			} else {
				setChargeStationList([]);
			}
		} catch (error) {
			handleErrorResponse("Error fetching charging station metrics");
			setChargeStationList([]);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchChargeStationList(timeRange);
	}, [fetchChargeStationList, timeRange]);

	const editBatteryInfo = useCallback((data) => {
		setStationToEdit(data);
		setEditModalOpen(true);
	}, []);

	const handleDelete = useCallback((chargeStation) => {
		setStationToDelete(chargeStation);
		setDeleteModalOpen(true);
	}, []);

	const confirmDelete = useCallback(() => {
		if (stationToDelete) {
			deleteChargeStation(stationToDelete.id)
				.then((res) => {
					setChargeStationList((prevList) =>
						prevList.filter(
							(station) => station.id !== stationToDelete.id
						)
					);
					setDeleteModalOpen(false);
					setStationToDelete(null);
				})
				.catch((err) => {
					handleErrorResponse("Error deleting charge station");
				});
		}
	}, [stationToDelete]);

	const handleEditSuccess = useCallback(
		(updatedStation) => {
			setChargeStationList((prevList) =>
				prevList.map((station) =>
					station.id === updatedStation.id ? updatedStation : station
				)
			);
			fetchChargeStationList(timeRange);
		},
		[fetchChargeStationList, timeRange]
	);

	const handleAddSuccess = useCallback(() => {
		fetchChargeStationList(timeRange);
	}, [fetchChargeStationList, timeRange]);

	const filter = useCallback(
		(ev) => {
			const param = ev.target.value.toLowerCase();
			if (!param) {
				setFilteredChargeStations(chargeStationList);
				return;
			}
			const filters = (station) =>
				station.chargeStationName.toLowerCase().includes(param) ||
				station.capacity.toString().includes(param) ||
				station.monthsRevenue.toString().includes(param) ||
				station.batteries_dispatched.toString().includes(param);

			const formattedData = chargeStationList.filter(filters);
			setFilteredChargeStations(formattedData);
		},
		[chargeStationList]
	);

	useEffect(() => {
		setFilteredChargeStations(chargeStationList);
	}, [chargeStationList]);

	const handleTimeRangeChange = useCallback(
		(event) => {
			const newTimeRange = event.target.value;
			setTimeRange(newTimeRange);
			fetchChargeStationList(newTimeRange);
		},
		[fetchChargeStationList]
	);

	const handleOpenDownloadModal = useCallback(() => {
		setIsDownloadModalOpen(true);
	}, []);

	const handleCloseDownloadModal = useCallback(() => {
		setIsDownloadModalOpen(false);
		setStartDate(null);
		setEndDate(null);
	}, []);

	const handleDownload = useCallback(() => {
		if (startDate && endDate) {
			downloadChargeStationMetrics(startDate, endDate)
				.then((result) => {
					issueSuccessMessage("Download successful");
					handleCloseDownloadModal();
				})
				.catch((error) => {
					handleErrorResponse("Download failed");
				});
		} else {
			handleErrorResponse("Please select both start and end dates");
		}
	}, [startDate, endDate, handleCloseDownloadModal]);

	const formatDate = (date) => {
		if (!date) return "";
		return dayjs(date).format("MMMM D, YYYY");
	};

	const getStartDateLabel = () => {
		return startDate
			? `Start Date: ${formatDate(startDate)}`
			: "Start Date";
	};

	const getEndDateLabel = () => {
		return endDate ? `End Date: ${formatDate(endDate)}` : "End Date";
	};

	return (
		<CardContainer
			title={title}
			hasActionableComponent={true}
			height={height}
			width={width}
			actionableComponent={
				<ActionableComponent
					showTimeRange={showTimeRange}
					showAddButton={showAddButton}
					showSearch={showSearch}
					showDownload={showDownload}
					searchPlaceholder="Search Charge Station"
					handleTimeRangeChange={handleTimeRangeChange}
					toggleAddModal={toggleModalAddModal}
					filter={filter}
					actionableText="Download data"
					handleActionableClick={handleOpenDownloadModal}
				/>
			}
			extraSX={{
				minHeight: "500px",
			}}
		>
			<CustomDataGrid
				columns={chargeStationListColumns}
				rows={filteredChargeStations}
				enableCheckboxSelection={false}
				isEmptyMessage="There are no charge stations to show"
				isLoading={isLoading}
				height="100%"
				width="100%"
				onRowClick={onRowClick}
			/>
			<AddNewStationModal
				toggleModal={toggleModalAddModal}
				modalOpen={AddmodalOpen}
				FormComponent={AddNewChargeStationForm}
				addStationService={addChargeStation}
				successMessageText="Charge Station added successfully!"
				title="Add New Charge Station"
				actionButtonText="Add Charge Station"
				onSuccess={handleAddSuccess}
			/>
			<EditStationModal
				toggleModal={toggleModalEditModal}
				modalOpen={editModalOpen}
				station={stationToEdit}
				onSuccess={handleEditSuccess}
				type="charge"
			/>
			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={() => setDeleteModalOpen(false)}
				itemType={"Charge Station"}
				itemName={
					stationToDelete?.chargeStationName || "Unknown Station"
				}
				onConfirmation={confirmDelete}
				confirmType={"delete"}
			/>
			<DateRangeReportModal
				title="Download Charge Station Metrics"
				modalOpen={isDownloadModalOpen}
				actionButtonText="Download"
				handleCloseModal={handleCloseDownloadModal}
				handleActionClick={handleDownload}
				handleStartDateChange={(newValue) => setStartDate(newValue)}
				handleEndDateChange={(newValue) => setEndDate(newValue)}
				startDate={startDate}
				endDate={endDate}
				startDateLabel={getStartDateLabel()}
				endDateLabel={getEndDateLabel()}
				minDate={dayjs().subtract(1, "year").toDate()}
				maxDate={dayjs().endOf("day").toDate()}
			/>
		</CardContainer>
	);
}
