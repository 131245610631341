import { BATTERY_ASSETS_COORDINATES, CHARGING_AND_POWER_USAGE, GET_LOCATION_NAME, GLOBAL_FINANCIAL_REPORT } from '../constants/url.constants';
import { handleErrorResponse } from '../util/util';
import { serviceAPI, serviceAPIDownloadFile } from './service';

export const getChargingAndPowerUsage = async (data) => {
  try {
		const response = await serviceAPI.get(
			CHARGING_AND_POWER_USAGE,
			{
				params: {
					...data,
				},
			}
		);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
}

export const downloadChargingAndPowerUsageReport = async (requestData) => {
  try {
    const response = await serviceAPIDownloadFile.get(
      CHARGING_AND_POWER_USAGE,
      {
        params: {
          ...requestData,
        },
        responseType: 'blob'
      }
    );
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const getAssetsCoordinates = async () => {
	try {
		const response = await serviceAPI.get(BATTERY_ASSETS_COORDINATES);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
}

export const getLocationName = async (lat, lon) => {
	try {
		const response = await serviceAPI.get(GET_LOCATION_NAME, {
			params: {
				lat,
				lon,
			},
		});
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
}

export const getGlobalFinancialReport = async (data) => {
	try {
		const response = await serviceAPI.get(
			GLOBAL_FINANCIAL_REPORT,
			{
				params: {
					...data,
				},
			}
		);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
}
