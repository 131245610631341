import React, { useCallback, useEffect, useState } from "react";
import { CardContainer } from "../global/containers";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import AddNewStationModal from "../modals/addNewStationModal";
import AddNewSwapStationForm from "../forms/addNewSwapStationForm";
import EditStationModal from "../modals/editStationModal";
import ConfirmationModal from "../modals/confirmationModal";
import { useNavigate } from "react-router-dom";
import { deleteSwapStation, addSwapStation } from "../../services/swapStation";
import { swappingStationMetrics } from "../../services/metricsService";
import ActionableComponent from "../global/actionableComponent";
import { downloadSwapStationMetrics } from "../../services/swapStation";
import DateRangeReportModal from "../modals/dateRangeReportModal";
import dayjs from "dayjs";
import { handleErrorResponse, issueSuccessMessage } from "../../util/util";

export default function SwapListCard({
	title = "Swap Stations",
	height = "75vh",
	width = "100%",
	showTimeRange = true,
	showAddButton = true,
	showSearch,
	showDownload = false,
	customColumns,
	onRowClick,
}) {
	const navigator = useNavigate();
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [stationToEdit, setStationToEdit] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [stationToDelete, setStationToDelete] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const [swapStationsData, setSwapStationsData] = useState({});
	const [filteredSwapStations, setFilteredSwapStations] = useState([]);
	const [timeRange, setTimeRange] = useState("month");

	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const toggleModalAddModal = useCallback(() => {
		setAddModalOpen((prevState) => !prevState);
	}, []);

	const toggleModalEditModal = useCallback(() => {
		setEditModalOpen((prevState) => !prevState);
	}, []);

	const swapListColumns = customColumns || [
		{
			field: "name",
			headerName: "Swap Station Name",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "total_batteries",
			headerName: "Battery Inventory Count(#)",
			minWidth: 200,
			flex: 1,
			valueFormatter: (value) => `${value} batteries`,
		},
		{
			field: "batteries_swapped",
			headerName: "Swap Count(#)",
			minWidth: 150,
			flex: 1,
			valueFormatter: (value) => `${value} swaps`,
		},
		{
			field: "revenue",
			headerName: `${
				timeRange.charAt(0).toUpperCase() + timeRange.slice(1)
			}'s Revenue(KES)`,
			minWidth: 150,
			flex: 1,
			valueFormatter: (value) => `${value} KES`,
		},

		{
			field: "actions",
			headerName: "Actions",
			width: 100,
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					width="100px"
					menuOptions={[
						{
							label: "View",
							onClickHandler: () =>
								navigator(`/swap-station/${params.row.id}`, {
									state: { swapStation: params.row },
								}),
						},
						{
							label: "Edit",
							onClickHandler: () => editStation(params.row),
						},
						{
							label: "Delete",
							onClickHandler: () => handleDelete(params.row),
						},
					]}
				/>
			),
		},
	];

	const fetchSwapStationList = useCallback(async function (range) {
		setIsLoading(true);
		try {
			const res = await swappingStationMetrics(range);
			setSwapStationsData(res);
			setFilteredSwapStations(res.stations);
		} catch (error) {
			console.error("Error fetching swap stations:", error);
			setSwapStationsData({});
			setFilteredSwapStations([]);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchSwapStationList(timeRange);
	}, [fetchSwapStationList, timeRange]);

	const editStation = useCallback((data) => {
		setStationToEdit(data);
		setEditModalOpen(true);
	}, []);

	const handleDelete = useCallback((swapStation) => {
		setStationToDelete(swapStation);
		setDeleteModalOpen(true);
	}, []);

	const confirmDelete = useCallback(() => {
		if (stationToDelete?.id) {
			deleteSwapStation(stationToDelete.id)
				.then(() => {
					setFilteredSwapStations((prevList) =>
						prevList.filter(
							(station) => station.id !== stationToDelete.id
						)
					);
					setDeleteModalOpen(false);
					setStationToDelete(null);
				})
				.catch((err) => {
					console.error("Error deleting swap station:", err);
				});
		}
	}, [stationToDelete]);

	const handleEditSuccess = useCallback(
		(updatedStation) => {
			setFilteredSwapStations((prevList) =>
				prevList.map((station) =>
					station.id === updatedStation.id ? updatedStation : station
				)
			);
			fetchSwapStationList(timeRange);
		},
		[fetchSwapStationList, timeRange]
	);

	const handleAddSuccess = useCallback(() => {
		fetchSwapStationList(timeRange);
	}, [fetchSwapStationList, timeRange]);

	const filter = useCallback(
		(ev) => {
			const param = ev.target.value.toLowerCase();
			if (!param) {
				setFilteredSwapStations(swapStationsData.stations);
				return;
			}
			const filters = (station) =>
				station.name.toLowerCase().includes(param) ||
				station.batteries_swapped.toString().includes(param) ||
				station.revenue.toString().includes(param) ||
				station.total_batteries.toString().includes(param);

			const formattedData = swapStationsData.stations.filter(filters);
			setFilteredSwapStations(formattedData);
		},
		[swapStationsData]
	);

	const handleTimeRangeChange = useCallback(
		(event) => {
			const newTimeRange = event.target.value;
			setTimeRange(newTimeRange);
			fetchSwapStationList(newTimeRange);
		},
		[fetchSwapStationList]
	);

	const handleOpenDownloadModal = useCallback(() => {
		setIsDownloadModalOpen(true);
	}, []);

	const handleCloseDownloadModal = useCallback(() => {
		setIsDownloadModalOpen(false);
		setStartDate(null);
		setEndDate(null);
	}, []);

	const handleDownload = useCallback(() => {
		if (startDate && endDate) {
			downloadSwapStationMetrics(startDate, endDate)
				.then(() => {
					handleCloseDownloadModal();
					issueSuccessMessage(
						"Swap station data download successful"
					);
				})
				.catch((error) => {
					handleErrorResponse("Swap station data download failed");
				});
		} else {
			handleErrorResponse("Please select both start and end dates");
		}
	}, [startDate, endDate, handleCloseDownloadModal]);

	const formatDate = (date) => {
		if (!date) return "";
		return dayjs(date).format("MMMM D, YYYY");
	};

	const getStartDateLabel = () => {
		return startDate
			? `Start Date: ${formatDate(startDate)}`
			: "Start Date";
	};

	const getEndDateLabel = () => {
		return endDate ? `End Date: ${formatDate(endDate)}` : "End Date";
	};

	return (
		<CardContainer
			title={title}
			hasActionableComponent={true}
			height={height}
			width={width}
			actionableComponent={
				<ActionableComponent
					showTimeRange={showTimeRange}
					showAddButton={showAddButton}
					showSearch={showSearch}
					showDownload={showDownload}
					searchPlaceholder="Search Swap Station"
					handleTimeRangeChange={handleTimeRangeChange}
					toggleAddModal={toggleModalAddModal}
					filter={filter}
					actionableText="Download data"
					handleActionableClick={handleOpenDownloadModal}
				/>
			}
			extraSX={{
				minHeight: "500px",
			}}
		>
			<CustomDataGrid
				isLoading={isLoading}
				columns={swapListColumns}
				rows={filteredSwapStations}
				enableCheckboxSelection={false}
				isEmptyMessage="There are no swap stations to show."
				width="100%"
				height="100%"
				onRowClick={onRowClick}
			/>
			<AddNewStationModal
				toggleModal={toggleModalAddModal}
				modalOpen={addModalOpen}
				FormComponent={AddNewSwapStationForm}
				addStationService={addSwapStation}
				successMessageText="Swap Station added successfully!"
				title="Add New Swap Station"
				actionButtonText="Add Swap Station"
				onSuccess={handleAddSuccess}
			/>
			<EditStationModal
				modalOpen={editModalOpen}
				toggleModal={toggleModalEditModal}
				station={stationToEdit}
				onSuccess={handleEditSuccess}
				type="swap"
			/>
			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={() => setDeleteModalOpen(false)}
				onConfirmation={confirmDelete}
				itemType="Swap Station"
				itemName={stationToDelete?.name ?? "Unknown Station"}
				confirmType="delete"
			/>
			<DateRangeReportModal
				title="Download Swap Station Metrics"
				modalOpen={isDownloadModalOpen}
				actionButtonText="Download"
				handleCloseModal={handleCloseDownloadModal}
				handleActionClick={handleDownload}
				handleStartDateChange={(newValue) => setStartDate(newValue)}
				handleEndDateChange={(newValue) => setEndDate(newValue)}
				startDate={startDate}
				endDate={endDate}
				startDateLabel={getStartDateLabel()}
				endDateLabel={getEndDateLabel()}
				minDate={dayjs().subtract(1, "year").toDate()}
				maxDate={dayjs().endOf("day").toDate()}
			/>
		</CardContainer>
	);
}
