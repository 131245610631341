import React, { useEffect, useRef, useState } from "react";
import { CardContainer } from "../global/containers";
import { PieChartItem } from "../global/charts";

export default function BatteryStatusCard() {
  const cardContainerRef = useRef();

  const [cardContainerWidth, setCardContainerWidth] = useState(undefined);

  useEffect(() => {
    if (cardContainerRef !== null && cardContainerRef.current !== null) {
      const containerWidth = cardContainerRef.current.getContainerWidth();
      if (!isNaN(containerWidth)) {
        setCardContainerWidth(containerWidth * 0.8);
      }
    }
  }, [cardContainerRef]);

  return (
    <CardContainer
      ref={cardContainerRef}
      title="Battery Status"
      width={{ xs: "100%", lg: "40%" }}
      // height={"50vh"}
      childrenContainerWidth={"95%"}
    >
      <PieChartItem
        height={300}
        width={cardContainerWidth}
        dataSeries={[
          {
            id: 0,
            value: 105,
            label: "Deployed",
            valueFormatter: (value) => `${value} batteries`,
          },
          {
            id: 1,
            value: 78,
            label: "Ready to Deploy",
            valueFormatter: (value) => `${value} batteries`,
          },
          {
            id: 2,
            value: 94,
            label: "Ready to Charge",
            valueFormatter: (value) => `${value} batteries`,
          },
          {
            id: 3,
            value: 54,
            label: "Charging",
            valueFormatter: (value) => `${value} batteries`,
          },
        ]}
      />
    </CardContainer>
  );
}
