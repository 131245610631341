import React, { useCallback, useEffect, useState } from "react";
import { CardContainer } from "../global/containers";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import AddBikeModal from "../modals/addBikeModal";
import { useNavigate } from "react-router-dom";
import {
	deleteElectricVehicles,
	listElectricVehicles,
} from "../../services/electricVehicle";
import EditBikeAssetModal from "../modals/editBikeAssetModal";
import ConfirmationModal from "../modals/confirmationModal";
import ActionableComponent from "../global/actionableComponent";

const ECI_STATES = {
	mv: "moving",
	idd: "idling",
	off: "off",
};

export default function BikeListCard() {
	const [filteredData, setFilteredData] = useState();

	const navigator = useNavigate();
	const [addmodalOpen, setAddModalOpen] = useState(false);
	const [editmodalOpen, setEditmodalOpen] = useState(false);
	const [mototbikeToEdit, setmototbikeToEdit] = useState();
	const [motobikeList, setMototbikeList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [bikeToDelete, setBikeToDelete] = useState(null);

	const motoBikeColumns = [
		{
			field: "bikeSerial",
			headerName: "Bike Serial No.",
			flex: 1,
			minWidth: 100,
		},
		{
			field: "currentMilage",
			headerName: "Current Mileage (km)",
			flex: 1,
			minWidth: 100,
		},
		{
			field: "riderName",
			headerName: "Rider Name",
			flex: 1,
			minWidth: 100,
		},
		{
			field: "bikeRegistration",
			headerName: "Registration No.",
			flex: 1,
			minWidth: 100,
		},
		{
			field: "pairedBatterySn",
			headerName: "Paired Battery S/N",
			flex: 1,
			minWidth: 100,
		},
		{
			field: "eciState",
			headerName: "ECI State",
			flex: 1,
			minWidth: 100,
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 1,
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					menuOptions={[
						{
							label: "View",
							onClickHandler: () =>
								navigator(`/motobikes/${params.row.id}`),
						},
						{
							label: "Edit",
							onClickHandler: () => editMotorBike(params.row),
						},
						{
							label: "Delete",
							onClickHandler: () => handleDelete(params.row),
						},
					]}
				/>
			),
		},
	];

	const fetchBikeListData = useCallback(async function () {
		setIsLoading(true);
		listElectricVehicles()
			.then((electricVehicles) => {
				const formatedData = electricVehicles.map((vehicle) => {
					const riderName = `${vehicle?.rider?.first_name} ${vehicle?.rider?.last_name} `;

					return {
						id: vehicle.id,
						bikeSerial: vehicle.serial_number,
						currentMilage: vehicle.millage ?? 0,
						riderName: riderName,
						bikeRegistration: vehicle?.registration_number,
						pairedBatterySn: vehicle.battery?.bat_sn ?? "-",
						eciState: ECI_STATES[vehicle?.eci?.state],
						...vehicle,
					};
				});
				setMototbikeList(formatedData);
				setFilteredData(formatedData);
			})
			.catch(() => {
				setMototbikeList([]);
			})
			.finally(() => setIsLoading(false));
	}, []);

	const filter = (ev) => {
		const param = ev.target.value.toLocaleLowerCase();
		if (param === "") {
			return setFilteredData(motobikeList);
		}
		const filters = (vehicle) =>
			vehicle?.registration_number.toLocaleLowerCase().includes(param) ||
			vehicle?.rider?.first_name.toLocaleLowerCase().includes(param) ||
			vehicle?.rider?.last_name.toLocaleLowerCase().includes(param) ||
			vehicle?.pairedBatterySn.toLocaleLowerCase().includes(param);
		const formatedData = motobikeList.filter(filters);
		setFilteredData(formatedData);
	};

	const toggleAddModal = useCallback(
		async function () {
			setAddModalOpen(!addmodalOpen);
		},
		[addmodalOpen]
	);

	const toggleDeleteModal = useCallback(
		async function () {
			setDeleteModalOpen(!deleteModalOpen);
		},
		[deleteModalOpen]
	);

	const toggleEditModal = useCallback(
		async function () {
			setEditmodalOpen(!editmodalOpen);
		},
		[editmodalOpen]
	);

	useEffect(() => {
		fetchBikeListData();
	}, [fetchBikeListData, toggleAddModal, toggleEditModal]);

	const editMotorBike = (data) => {
		setmototbikeToEdit(data);
		toggleEditModal();
	};

	const handleDelete = (motorbike) => {
		setBikeToDelete(motorbike);
		toggleDeleteModal();
	};

	const deleteEv = () => {
		deleteElectricVehicles(bikeToDelete.id)
			.then((res) => {
				setMototbikeList(
					motobikeList.filter((value) => value.id !== bikeToDelete.id)
				);
				setFilteredData(
					filteredData.filter((value) => value.id !== bikeToDelete.id)
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => toggleDeleteModal());
	};

	return (
		<CardContainer
			title="Bike Assets"
			height="auto"
			width="100%"
			childrenContainerWidth="97%"
			hasActionableComponent={true}
			actionableComponent={
				<ActionableComponent
					showAddButton={true}
					showSearch={true}
					filter={filter}
					toggleAddModal={toggleAddModal}
					searchPlaceholder="Search Electric Vehicles"
				/>
			}
		>
			<CustomDataGrid
				columns={motoBikeColumns}
				rows={filteredData}
				isLoading={isLoading}
				isEmptyMessage="There are no bike assets to show"
				width="100%"
				height="65vh"
			/>
			<AddBikeModal
				toggleModal={toggleAddModal}
				modalOpen={addmodalOpen}
			/>
			<EditBikeAssetModal
				toggleModal={toggleEditModal}
				bikeAsset={mototbikeToEdit}
				modalOpen={editmodalOpen}
			/>
			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={toggleDeleteModal}
				onConfirmation={deleteEv}
				itemType="Electric vehicle"
				itemName={bikeToDelete?.registration_number}
				confirmType="delete"
			/>
		</CardContainer>
	);
}
