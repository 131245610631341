import React, { useCallback, useEffect, useState } from "react";
import { CardContainer } from "../global/containers";
import { CustomDataGrid } from "../global/customTable";
import { getGlobalDispatchOperations } from "../../services/chargeStationService";

export default function RecentDispatchRequestscard() {
  const [recentDispatchRequests, setRecentDispatchRequests] = useState([]);
  const [isLoadingData, setIsLoading] = useState(false);

  const recentDispatchOperationsColumns = [
    {
      field: "id",
      headerName: "Trip ID",
      width: 50,
      flex: 0.5,
    },
    {
      field: "swappingStation",
      headerName: "Swap Station",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "chargingStation",
      headerName: "Charge Station",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "batteriesCount",
      headerName: "Batteries",
      minWidth: 75,
      flex: 0.75,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 75,
      flex: 0.75,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 75,
      flex: 0.75,
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   align: "center",
    //   renderCell: (params) => (
    //     <CustomDataGridActionsDropdownMenu
    //       menuOptions={[
    //         {
    //           label: "View",
    //           onClickHandler: () => navigator(`/swap-station/${params.row.id}`),
    //         },
    //       ]}
    //     />
    //   ),
    // },
  ];

  const fetchRecentDispatchRequests = useCallback(async function () {
    setIsLoading(true);

    getGlobalDispatchOperations()
      .then((response) => {
        setRecentDispatchRequests(response); // TODO: Reset the list
      })
      .finally(() => setIsLoading(false));
    
  }, []);

  useEffect(() => {
    fetchRecentDispatchRequests();
  }, [fetchRecentDispatchRequests]);

  return (
    <CardContainer
      title="Recent Dispatch Operations"
      width={{ xs: "100%", lg: "60%" }}
      height={"400px"}
      childrenContainerWidth={"100%"}
      childrenContainerPadding={0}
    >
      <CustomDataGrid
        isLoading={isLoadingData}
        isEmptyMessage="There are no dispatch operations to show."
        columns={recentDispatchOperationsColumns}
        rows={recentDispatchRequests}
        width="100%"
        height="100%"
      />
    </CardContainer>
  );
}
