import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React from 'react'

function CustomDatePicker({ label, handleChange, value, maxDate=null, minDate=null }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label={label} onChange={handleChange} value={dayjs(value)} maxDate={dayjs(maxDate)} />
    </LocalizationProvider>
  )
}

export default CustomDatePicker