import React from "react";
import {
	ColumnContainer,
	ModalContainer,
	RowContainer,
} from "../global/containers";
import InfoDetails from "../global/infoText";

export default function ViewChargingAndPowerUsageModal({
	modalOpen,
	toggleModal,
  selectedRow,
}) {
	return (
		<ModalContainer
			title={`View Charging & Power Usage:- ${selectedRow?.chargingStation ?? "Unknown"}`}
			modalOpen={modalOpen}
			height="auto"
			type="in-actionable"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={toggleModal}
		>
      <ColumnContainer
        padding="0px"
        alignItems="flex-start"
        justifyContent="space-between"
        marginBottom={3}
      >
        <RowContainer
          justifyContent="space-between"
          padding="0px"
          marginBottom="0px"
          alignItems="start"
          width="100%"
          extraSX={{ flexWrap: { xs: "wrap", lg: "nowrap" } }}
        >
          <InfoDetails
            label="Charging Station Name"
            value={selectedRow?.chargingStation ?? "--"}
            labelProps={{ sx: { fontWeight: "bold" } }}
            valueProps={{ sx: { wordBreak: "break-word" } }}
          />
          <InfoDetails
            label="Batteries Charged in Period"
            value={selectedRow?.batteriesCount ?? "--"}
            labelProps={{ sx: { fontWeight: "bold" } }}
            valueProps={{ sx: { wordBreak: "break-word" } }}
          />
        </RowContainer>
        <RowContainer
          justifyContent="space-between"
          padding="0px"
          marginBottom="0px"
          alignItems="start"
          width="100%"
          extraSX={{ flexWrap: { xs: "wrap", lg: "nowrap" } }}
        >
          <InfoDetails
            label="Power Used in Period (kWh)"
            value={selectedRow?.powerUsage ?? "--"}
            labelProps={{ sx: { fontWeight: "bold" } }}
            valueProps={{ sx: { wordBreak: "break-word" } }}
          />
          <InfoDetails
            label="Costs Incurred for Period (Kshs)"
            value={selectedRow?.cost ?? "--"}
            labelProps={{ sx: { fontWeight: "bold" } }}
            valueProps={{ sx: { wordBreak: "break-word" } }}
          />
        </RowContainer>
        <RowContainer
          justifyContent="space-between"
          padding="0px"
          marginBottom="0px"
          alignItems="start"
          width="100%"
          extraSX={{ flexWrap: { xs: "wrap", lg: "nowrap" } }}
        >
          <InfoDetails
            label="Amount Owed for Period (Kshs)"
            value={selectedRow?.amountOwed ?? "--"}
            labelProps={{ sx: { fontWeight: "bold" } }}
            valueProps={{ sx: { wordBreak: "break-word" } }}
          />
        </RowContainer>
      </ColumnContainer>
		</ModalContainer>
	);
}
