import React, { useCallback, useEffect, useState } from "react";
import {
  CardContainer,
  ColumnContainer,
  RowContainer,
} from "../global/containers";
import StatItem from "../global/statItem";
import { getGlobalFinancialReport } from "../../services/dashboardService";

export default function FinancialReportDashboardCard() {
  const [stats, setStats] = useState()

  const fetchStats = useCallback( async function(){
    getGlobalFinancialReport()
      .then((res => {
        setStats(res)
      }))
  },[]);

  useEffect(() => {
    fetchStats()
  },[fetchStats])

  return (
    <CardContainer
      title="Financial Report Stats"
      width={{ xs: "100%", lg: "33%" }}
      height="auto"
      childrenContainerWidth="97%"
    >
      <ColumnContainer
        width="100%"
        height="auto"
        padding="5px 10px"
        marginBottom="5px"
        extraSX={{
          gap: "10px",
        }}
      >
        <RowContainer
          justifyContent="space-between"
          alignItems="start"
          padding="0px"
          marginBottom="0px"
          responsive={false}
          extraSX={{
            gap: "10px",
          }}
        >
          <StatItem title={"Power Used At Charging Stations"} value={`${stats?.powerUsage ?? "-"} kWh`} />
          <StatItem title={"Amount Owed to Charging Stations"} value={`${stats?.accountsPayable ?? "-"} Kshs`} />
        </RowContainer>
        <RowContainer
          justifyContent="space-between"
          alignItems="start"
          padding="0px"
          marginBottom="0px"
          responsive={false}
          extraSX={{
            gap: "10px",
          }}
        >
          <StatItem title={"Conversion Rate"} value={`${stats?.conversionRate  ?? "-"} hours`} />
          <StatItem title={"Turn Around Time"} value={`${stats?.turnAroundTime ?? "-"} hours`} />
        </RowContainer>
      </ColumnContainer>
    </CardContainer>
  );
}
